
.page-layout {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-content: stretch;


    .page-desktop-navigation {
        min-width: 300px;
        max-width: 300px;
        width: 300px;
    }
    .page-container {
        width: 100%;
    }
}

.triple-card-list {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;


    @media (max-width: $breakpoint-xlarge) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $breakpoint-small) {
        grid-template-columns: 1fr;
    }
}

.full-name-inputs {
    display: flex;
    justify-content: stretch;
    align-content: stretch;


    > div {
        width: 100%;
    }
    > div:first-child {
        padding-right: 10px;
    }
}