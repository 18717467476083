body {
    background: $grey;
}
canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


// Font Awesome
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-11 {
    width: 0.6875em;
}
.fa-5x {
    font-size: 5em;
}


// Colours
.uk-background-ghost {
    border: 1px solid $borderGrey;
    border-radius: $border-rounded-border-radius;
}

.uk-background-warning {
    background-color: #faa05a;
}

.uk-background-danger {
    background-color: #f0506e;
}

.uk-background-lighter-secondary {
    background: lighten($darkestBlue, 4%);
}



// Form Inputs
label {
    cursor: pointer;
}

.uk-form-controls > label,
.uk-input,
.uk-textarea,
.uk-select {
    font-size: 15px;
}

.uk-input,
.uk-textarea,
.uk-select,
.uk-button {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-radius: 3px;

    text-transform: none;


    @media (max-width: 640px) {
        margin-bottom: 5px !important;
    }

    &.uk-button-large {
        font-size: 18px;
    }
}

.uk-textarea {
    padding-top: 10px;
    padding-bottom: 10px;
}

.uk-input-with-close {
    padding-right: 40px;
}

.uk-form-icon-textarea {
    margin-top: 10px;

    align-items: normal;
}

.uk-form-controls-checkbox {
    position: relative;

    display: block;
    padding-left: 30px;


    .uk-checkbox {
        position: absolute;
        top: 7px;
        left: 0;
    }
}

.uk-radio,
.uk-checkbox {
    margin-top: -2px;
    margin-right: 3px;
}

.uk-badge {
    height: auto;
    min-height: 22px;
    padding: 4px 15px;
    flex-direction: column;

    vertical-align: baseline;

    line-height: normal;
    font-size: 14px;


    a {
        margin-left: 10px;

        color: $white;
        text-decoration: underline;


        &:hover {
            text-decoration: none;
        }
    }
}

.uk-badge-error {
    background: $red;
}

.uk-badge-warning {
    background: $orange;
}


// Cards
.uk-card,
.uk-card-body {
    border-radius: 3px;
}

.uk-card-default {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}


// Spinner
.uk-spinner > * > * {
    stroke: $blue !important;
    stroke-width: 2px !important;
}


// Logo
.uk-logo {
    padding: 0;
    height: 40px;
    width: 200px;


    img {
        height: 40px;
        width: 200px;
    }

    @media (max-width: 400px) {
        height: 39px;
        width: 210px;


        img {
            height: 39px;
            width: 210px;
        }
    }

    &.icon {
        height: 40px;
        width: 33px;


        img {
            height: 40px;
            width: 33px;
        }

        @media (max-width: 400px) {
            height: 39px;
            width: 32px;


            img {
                height: 39px;
                width: 32px;
            }
        }
    }
}


// Connections
.connection-logo {
    height: 60px;

    background-position: 50%;
    background-repeat: no-repeat;
    vertical-align: top;

    text-indent: -9999px;


    &.slack {
        width: 60px;
        background-image: url(/img/connections/connection-slack.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.telegram {
        width: 60px;
        background-image: url(/img/connections/connection-telegram.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.googleCalendar,
    &.google_calendar {
        width: 60px;
        background-image: url(/img/connections/connection-google-calendar.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.googleHangoutsChat,
    &.google_hangouts_chat {
        width: 60px;
        background-image: url(/img/connections/connection-google-hangouts-chat.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.outlookCalendar,
    &.outlook_calendar {
        width: 60px;
        background-image: url(/img/connections/connection-outlook-calendar.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.discord {
        width: 60px;
        background-image: url(/img/connections/connection-discord.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.microsoftTeams,
    &.microsoft_teams {
        width: 60px;
        background-image: url(/img/connections/connection-microsoft.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.mattermost {
        width: 60px;
        background-image: url(/img/connections/connection-mattermost.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.twist {
        width: 60px;
        background-image: url(/img/connections/connection-twist.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.ryver {
        width: 60px;
        background-image: url(/img/connections/connection-ryver.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.chanty {
        width: 60px;
        background-image: url(/img/connections/connection-chanty.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.flock {
        width: 60px;
        background-image: url(/img/connections/connection-flock.svg);
        background-size: 50px auto;
        background-position: 50%;
    }
    &.url,
    &.team,
    &.sms,
    &.email {
        background: none;
        padding: 0 5px;

        text-align: center;
        text-indent: 0;


        .svg-inline--fa {
            height: 50px;
            width: 50px;
            padding-top: 5px;

            color: $blue;
        }
    }
}

.connection-logo-small {
    height: 50px;

    background-position: 50%;
    background-repeat: no-repeat;
    vertical-align: top;

    text-indent: -9999px;


    &.telegram {
        width: 50px;
        background-image: url(/img/connections/connection-telegram.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.slack {
        width: 50px;
        background-image: url(/img/connections/connection-slack.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.googleCalendar,
    &.google_calendar {
        width: 50px;
        background-image: url(/img/connections/connection-google-calendar.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.googleHangoutsChat,
    &.google_hangouts_chat {
        width: 50px;
        background-image: url(/img/connections/connection-google-hangouts-chat.svg);
        background-size: 37px auto;
        background-position: 50%;
    }
    &.outlookCalendar,
    &.outlook_calendar {
        width: 50px;
        background-image: url(/img/connections/connection-outlook-calendar.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.discord {
        width: 50px;
        background-image: url(/img/connections/connection-discord.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.microsoftTeams,
    &.microsoft_teams {
        width: 50px;
        background-image: url(/img/connections/connection-microsoft.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.mattermost {
        width: 50px;
        background-image: url(/img/connections/connection-mattermost.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.twist {
        width: 50px;
        background-image: url(/img/connections/connection-twist.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.ryver {
        width: 50px;
        background-image: url(/img/connections/connection-ryver.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.chanty {
        width: 50px;
        background-image: url(/img/connections/connection-chanty.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.flock {
        width: 50px;
        background-image: url(/img/connections/connection-flock.svg);
        background-size: 40px auto;
        background-position: 50%;
    }
    &.url,
    &.team,
    &.sms,
    &.email {
        background: none;
        padding: 0 5px;

        text-align: center;
        text-indent: 0;


        .svg-inline--fa {
            height: 40px;
            width: 40px;
            padding-top: 5px;

            color: $blue;
        }
    }
}

.connection-logo-micro {
    height: 24px;

    background-position: 50%;
    background-repeat: no-repeat;
    vertical-align: top;


    &.telegram {
        width: 24px;
        background-image: url(/img/connections/connection-telegram.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.slack {
        width: 24px;
        background-image: url(/img/connections/connection-slack.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.googleCalendar,
    &.google_calendar {
        width: 24px;
        background-image: url(/img/connections/connection-google-calendar.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.googleHangoutsChat,
    &.google_hangouts_chat {
        width: 24px;
        background-image: url(/img/connections/connection-google-hangouts-chat.svg);
        background-size: 18px auto;
        background-position: 50%;
    }
    &.outlookCalendar,
    &.outlook_calendar {
        width: 24px;
        background-image: url(/img/connections/connection-outlook-calendar.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.discord {
        width: 24px;
        background-image: url(/img/connections/connection-discord.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.microsoftTeams,
    &.microsoft_teams {
        width: 24px;
        background-image: url(/img/connections/connection-microsoft.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.mattermost {
        width: 24px;
        background-image: url(/img/connections/connection-mattermost.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.twist {
        width: 24px;
        background-image: url(/img/connections/connection-twist.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.ryver {
        width: 24px;
        background-image: url(/img/connections/connection-ryver.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.chanty {
        width: 24px;
        background-image: url(/img/connections/connection-chanty.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.flock {
        width: 24px;
        background-image: url(/img/connections/connection-flock.svg);
        background-size: 20px auto;
        background-position: 50%;
    }
    &.team,
    &.email,
    &.url,
    &.sms {
        background: none;
        padding: 0;

        text-align: center;
        text-indent: 0;


        .svg-inline--fa {
            height: 24px;
            width: 24px;
            padding-top: 0;

            color: $blue;
        }
    }
}


// Timezones
.timezone-wrapper {
    min-height: 130px;
}


// Plan Overview
.plan-overview {
    .icon {
        display: inline-block;
        min-width: 25px;
        text-align: center;
    }
}


// Stripe
.StripeElement {
    padding: 0 10px;

    border: 1px solid $global-border;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    background: $white;
    transition: 0.2s ease-in-out;
    transition-property: border;
}

.StripeElement--focus {
    border-color: $blue;
}

.StripeElement--invalid {
    border-color: $global-danger-background;
}
