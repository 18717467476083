@import 'variables.scss';


// Background
.uk-background-green { background-color: $blue; }
.uk-background-dark-green { background-color: $darkBlue; }
.uk-background-darkest-green { background-color: $darkestBlue; }

@mixin hook-offcanvas-bar() {
  background: $darkBlue;
}

@mixin hook-modal-dialog() {
  border-radius: 3px;
}



// Alerts
@mixin hook-alert() {
    padding-top: 20px;
    padding-bottom: 20px;

    border-radius: 5px;
    border: 2px solid $darkBlue;
    background: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);

    color: #333;

    a {
        text-decoration: underline;


        &:hover {
            text-decoration: none;
        }
    }
    p {
        color: #333;
    }
}

@mixin hook-alert-primary() {
    background: $white;
    border-color: $blue;

    color: #333;
}

@mixin hook-alert-success() {
    border-color: $green;
}

@mixin hook-alert-warning() {
    border-color: $orange;
}

@mixin hook-alert-danger() {
    border-color: $red;
}

@mixin hook-alert-misc() {
    .uk-alert-info {
        background: $white;
        border-color: $blue;

        color: #333;
    }
}
