
.uk-logo {
    display: inline-block;
    text-align: center;
}

.login-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;

    padding: 0 0 5px 0;


    .item {
        padding: 5px 10px 10px 0;

        a {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            padding: 12px 12px;

            border: 1px solid $borderGrey;
            border-radius: 4px;
            transition: all $transition-duration;

            color: $global-color;


            &:hover {
                background: $mutedGrey;
            }
        }
        h3 {
            color: $textColor;
        }
        img {
            width: 32px;
            height: 32px;
        }
        span {
            display: inline-block;

            line-height: 32px;
        }
    }
}
